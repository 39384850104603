import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../../store/reducers/user";
import { fetchData } from "../../services/api";
import { alertNotification } from "../../services/notications";
import getUserInfoByToken from "../../services/user";
import Cookies from "js-cookie";

import logo from "../../assets/images/logo_circle.png";
import eu from "../../assets/images/eu.png";
import eu1 from "../../assets/images/eu1.png";
import "./Login.scss";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const defaultEmail = Cookies.get("email") || "";

  const [values, setValues] = useState({
    email: defaultEmail,
    password: "",
    rememberMe: true,
  });

  const changeHandler = (e) => {
    setValues((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const toggleHandler = (e) => {
    setValues((state) => ({
      ...state,
      [e.target.name]: e.target.checked,
    }));
  };

  const loginHandler = async (e) => {
    e.preventDefault();

    try {
      const response = await fetchData("/users/login", {
        email: values.email,
        password: values.password,
      });

      if (response.status !== 201) throw new Error(response.data.message);

      if (!response.data.data || !response.data.data.accessToken) return;

      dispatch(
        login({
          accessToken: response.data.data.accessToken,
          user: await getUserInfoByToken(response.data.data.accessToken),
          remember: values.rememberMe,
          email: values.email,
        })
      );

      navigate("/");
    } catch (error) {
      if (error.response.data.message) {
        alertNotification("Неправилни входни данни!");
      }
    }
  };

  return (
    <div id="auth">
      <div className="row h-100">
        <div className="col-lg-5 col-12">
          <div id="auth-left">
            <div className="auth-logo">
              <img src={logo} alt="Logo" srcSet="" />
            </div>
            <h1 className="auth-title">Вход</h1>
            <form action="index.html" onSubmit={loginHandler}>
              <div className="form-group position-relative has-icon-left mb-4">
                <input
                  type="email"
                  className="form-control form-control-xl"
                  placeholder="Емайл"
                  onChange={changeHandler}
                  name="email"
                  value={values.email}
                  required
                />
                <div className="form-control-icon">
                  <i className="bi bi-person"></i>
                </div>
              </div>
              <div className="form-group position-relative has-icon-left mb-4">
                <input
                  type="password"
                  className="form-control form-control-xl"
                  placeholder="Парола"
                  name="password"
                  onChange={changeHandler}
                  value={values.password}
                  required
                />
                <div className="form-control-icon">
                  <i className="bi bi-shield-lock"></i>
                </div>
              </div>
              <div className="form-check form-check-lg d-flex align-items-end">
                <input
                  className="form-check-input me-2"
                  type="checkbox"
                  checked={values.rememberMe}
                  id="flexCheckDefault"
                  name="rememberMe"
                  onChange={toggleHandler}
                />
                <label
                  className="form-check-label text-gray-600"
                  htmlFor="flexCheckDefault"
                >
                  Запомни ме
                </label>
              </div>
              <button
                type="submit"
                className="btn btn-primary btn-block btn-lg shadow-lg mt-5"
              >
                Влез
              </button>
            </form>

            <div className="footer">
              <div className="footer-img">
                <img src={eu} alt="EU" />
                <img src={eu1} alt="EU" />
              </div>
              <p className="footer-text">
                Решения в областта на информационните и комуникационни
                технологии и киберсигурността в малките и средните предприятия
              </p>
            </div>

            <div className="version-info">
              <p className="mb-1">LMS v.1.0 от дата 06.11.2023</p>
              <p className="mt-1">Валидна до 06.11.2026</p>
            </div>
          </div>

          <div className="footer">
            <div className="footer-img">
              <img src={eu} alt="EU" />
              <img src={eu1} alt="EU" />
            </div>
            <p className="footer-text">
              Решения в областта на информационните и комуникационни технологии
              и киберсигурността в малките и средните предприятия
            </p>
          </div>
        </div>
        <div className="col-lg-7 d-none d-lg-block">
          <div id="auth-right"></div>
        </div>
      </div>
    </div>
  );
};

export default Login;
