import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { fetchData, patchData } from "../../../services/api";

import TestInfo from "../../../components/AddTestsBody/TestInfo";
import CreateQuestions from "../../../components/AddTestsBody/CreateQuestions";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import TestPreview from "../../../components/AddTestsBody/TestPreview";

const EditTest = () => {
  const [step, setStep] = useState(0);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const testId = searchParams.get("test_id");

  const [testInfo, setTestInfo] = useState({});
  const [questions, setQuestions] = useState([]);
  const [initInfo, setInitInfo] = useState({});

  useEffect(() => {
    async function getTest() {
      try {
        const response = await fetchData(`/tests/by-id?test_id=${testId}`);
        if (response.status !== 201) throw new Error();
        const data = response.data.data;
        setTestInfo({
          test_id: data.test_id,
          name: data.name,
          start_date: data.start_date,
          end_date: data.end_date,
          module: data?.module_id,
          module_name: data?.module_name,
        });
        setQuestions(data.questions);
        setInitInfo({
          test_id: data.test_id,
          name: data.name,
          start_date: data.start_date,
          end_date: data.end_date,
          module: data.module_id,
          questions: data.questions,
          module_name: data.module_name,
        });
        setStep(1);
      } catch (err) {
        console.log(err);
      }
    }

    getTest();
  }, []);

  const nextStep = () => {
    let currStep = step;
    currStep++;
    if (currStep <= 2) setStep(currStep);
  };

  const prevStep = () => {
    let currStep = step;
    currStep--;
    if (currStep >= 1) setStep(currStep);
    else {
      navigate("/admin/manage-tests");
    }
  };

  const areAnswersIdentical = (arr1, arr2) => {
    if (
      !Array.isArray(arr1) ||
      !Array.isArray(arr2) ||
      arr1.length !== arr2.length
    ) {
      return false;
    }

    arr1.sort((a, b) => a.answer_id - b.answer_id);
    arr2.sort((a, b) => a.answer_id - b.answer_id);

    for (let i = 0; i < arr1.length; i++) {
      const item1 = arr1[i];
      const item2 = arr2[i];

      if (
        item1.answer_id !== item2.answer_id ||
        item1.answer_title !== item2.answer_title ||
        item1.is_correct !== item2.is_correct
      ) {
        return false;
      }
    }

    return true;
  };

  const areQuestionsIdentical = (arr1, arr2) => {
    if (
      !Array.isArray(arr1) ||
      !Array.isArray(arr2) ||
      arr1.length !== arr2.length
    ) {
      return false;
    }

    arr1.sort((a, b) => a.question_id - b.question_id);
    arr2.sort((a, b) => a.question_id - b.question_id);

    for (let i = 0; i < arr1.length; i++) {
      const item1 = arr1[i];
      const item2 = arr2[i];

      if (
        item1.question_id !== item2.question_id ||
        item1.question_type !== item2.question_type ||
        item1.question_title !== item2.question_title ||
        item1.question_description !== item2.question_description ||
        item1.question_open_answer_help !== item2.question_open_answer_help
      ) {
        return false;
      }

      if (!areAnswersIdentical(item1.answers, item2.answers)) {
        return false;
      }
    }

    return true;
  };

  const hasChanges = (data) => {
    if (data.name !== initInfo.name) return true;
    if (data.start_date !== initInfo.start_date) return true;
    if (data.end_date !== initInfo.end_date) return true;
    if (!areQuestionsIdentical(data.questions, initInfo.questions)) return true;
    if (data.module !== initInfo.module) return true;
    if (data.module_name !== initInfo.module_name) return true;
    return false;
  };

  const save = async (data) => {
    try {
      const response = await patchData("/tests/edit", data);
      if (response.status !== 200) throw new Error(response.data);
      Swal.fire({
        title: " Успешно променихте теста!",
        icon: "success",
        confirmButtonText: "Към тестовете",
      }).then(function () {
        navigate("/admin/manage-tests");
      });
    } catch (err) {
      console.log(err);
    }
  };

  const updateTestInfo = (newTestData) => {
    setTestInfo(newTestData);
  };

  const updateQuestions = (newQuestionsData) => {
    setQuestions(newQuestionsData);
  };

  const submitEdit = (newQuestionsData) => {
    const data = {
      ...testInfo,
      questions: newQuestionsData,
    };
    setTestInfo(data);
    if (!hasChanges(data)) {
      Swal.fire({
        title: "Неуспешна промяна на тест!",
        icon: "error",
        text: "Няма намерени промени по теста!",
      });
      return;
    }
    showPreview(data);
  };

  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [previewData, setPreviewData] = useState({});

  const showPreview = (data) => {
    setPreviewData(data);
    setShowPreviewModal(true);
  };
  const submitPreview = async () => {
    setShowPreviewModal(false);
    save(previewData);
  };

  const methods = {
    onNextPage: nextStep,
    onPrevPage: prevStep,
    onUpdateTest: updateTestInfo,
    onUpdateQuestions: updateQuestions,
    onSubmit: submitEdit,
  };

  return (
    <div className="row">
      <div className="create-module card">
        <div className="header">
          <h3>Промяна на тест</h3>
          <ul className="steps">
            <li className={step >= 1 ? "active" : ""}>Информация</li>
            <li className={step >= 2 ? "active" : ""}>Въпроси</li>
          </ul>
        </div>
        <div className="card-body">
          {step === 1 &&
            <TestInfo testData={testInfo} methods={methods} />}

          {step === 2 && (
            <CreateQuestions questionsData={questions} methods={methods} />
          )}

          {showPreviewModal && (
            <TestPreview
              show={showPreviewModal}
              testData={previewData}
              hide={() => {
                setShowPreviewModal(false);
              }}
              submit={submitPreview}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default EditTest;
