import React, { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { logout } from "./store/reducers/user";
import getUserInfoByToken from "./services/user";

import featherIcons from "feather-icons";
import bootstrapBundle from "bootstrap/dist/js/bootstrap.bundle";

//admin pages
import ManageUsersPage from "./pages/AdminPages/ManageUsers";
import ManageModulesPage from "./pages/AdminPages/ManageModules";
import ManageTestsPage from "./pages/AdminPages/ManageTests";
import AddTestPage from './pages/AdminPages/AddTest'
import EditTestPage from './pages/AdminPages/EditTest'
import CreateModule from "./pages/AdminPages/CreateModule";
//refercences pages
import EmployeesInfo from "./pages/ReferencesPages/EmployeesInfo";
import Grades from "./pages/ReferencesPages/Grades";
import Materials from "./pages/ReferencesPages/Materials";
import Results from "./pages/Results";
//other
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import LoginPage from "./pages/Login";
import HomePage from "./pages/Home";
import Profile from "./pages/ProfileInfo";
import ModulesLanding from "./pages/ModulesLanding";
import ModulePage from "./pages/ModulePage";
import Test from "./components/Test";
import TestResult from "./components/Test/Result";
import PageNotFound from "./pages/Errors/PageNotFound";
import TestsRef from "./pages/ReferencesPages/Tests"
import StaffProgress from "./pages/ReferencesPages/StaffProgress";
import ForbiddenPage from "./pages/Errors/ForbiddenPage";
import MainFooter from "./components/ui/Footers/MainFooter";


import { hasPermission } from "./services/routePermission";
import TestEvaluate from "./pages/TestEvaluate";
import ChatRoom from "./pages/ChatPage/ChatRoom";
import { fetchCardsInfo, fetchLandingInfo, fetchNotifications } from './store/reducers/data/home'; // import the action
import { fetchModules } from "./store/reducers/data/modules";

function App() {
  window.bootstrap = bootstrapBundle;
  featherIcons.replace();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation().pathname;

  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    const userInfo = getUserInfoByToken(Cookies.get("accessToken"));
    if (!userInfo && location !== "/login") {
      dispatch(logout());
      navigate("/login");
    }
  });

  useEffect(() => {
    if (user && user.id) {
      dispatch(fetchModules())
      dispatch(fetchCardsInfo());
      dispatch(fetchLandingInfo());
      dispatch(fetchNotifications());
    }
  }, [user]);

  useEffect(() => {
    if (location === '/login') return;
    const userInfo = getUserInfoByToken(Cookies.get("accessToken"));
    if (userInfo) {
      const hasPerm = hasPermission(location, userInfo.role_name);
      if (!hasPerm) navigate('/forbidden-page')
    }
  }, [location])

  return (
    <div className="App">
      {location !== "/login"
        ? (
          <>
            <Sidebar />
            {location !== '/module' && location !== '/forbidden-page' && <Header />}
            <main className="main">
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/admin/manage-users" element={<ManageUsersPage />} />
                <Route path="/admin/manage-modules" element={<ManageModulesPage />} />
                <Route path="/admin/manage-tests" element={<ManageTestsPage />} />
                <Route path="/admin/manage-tests/add" element={<AddTestPage />} />
                <Route path="/admin/manage-tests/edit" element={<EditTestPage />} />
                <Route path="/admin/manage-modules/create-module" element={<CreateModule />} />
                <Route path="/admin/manage-modules/edit-module" element={<CreateModule />} />
                <Route path="/my-courses" element={<ModulesLanding />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/module" element={<ModulePage />} />
                <Route path="/test" element={<Test />} />
                <Route path="/test/result" element={<TestResult />} />
                <Route path="/test/evaluate" element={<TestEvaluate />} />
                <Route path="/results" element={<Results />} />
                <Route path="/references/grades" element={<Grades />} />
                <Route path="/references/staff-members" element={<EmployeesInfo />} />
                <Route path="/references/materials" element={<Materials />} />
                <Route path="/references/tests" element={<TestsRef />} />
                <Route path="/references/study-progress" element={<StaffProgress />} />
                <Route path="/chat" element={<ChatRoom />} />
                <Route path="/forbidden-page" element={<ForbiddenPage />} />
                <Route path="*" element={<PageNotFound />} />
              </Routes>

              <MainFooter />
            </main>
          </>
        ) : (
          <main>
            <Routes>
              <Route path="/login" element={<LoginPage />} />
            </Routes>
          </main>
        )}

      <ToastContainer />
    </div>
  );
}
export default App;
