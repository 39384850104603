import { Form, Modal, Accordion } from "react-bootstrap"
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css";
const TestPreviwBody = ({ testData }) => {
  console.log(testData)

  return (
    <Modal.Body>
      <Form.Group>
        <h6>Име на тест</h6>
        <Form.Control
          as='input'
          disabled
          value={testData?.name || ""}
        />
      </Form.Group>
      <Form.Group className="pt-3">
        <h6>Асоцииран модул</h6>
        <Form.Control
          as='input'
          disabled
          value={testData?.module?.label || "Модул не е избран"}
        />
      </Form.Group>
      <Form.Group className="pt-3">
        <h6>Начална дата</h6>
        <Flatpickr
          className="form-control"
          value={testData?.start_date || ""}
          options={{
            enableTime: true,
            dateFormat: "d/m/Y H:i",
            time_24hr: true
          }}
          disabled
        />
      </Form.Group>
      <Form.Group className="pt-3">
        <h6>Крайна дата</h6>
        <Flatpickr
          className="form-control"
          value={testData?.end_date || ""}
          options={{
            enableTime: true,
            dateFormat: "d/m/Y H:i",
            time_24hr: true
          }}
          disabled
        />
      </Form.Group>
      <Form.Group className="pt-4">
        <h6>Въпроси</h6>
        <Accordion defaultActiveKey="0" flush>
          {
            testData?.questions.map((question, idx) => (
              <Accordion.Item eventKey={question.question_id} key={question.question_id}>
                <div className="accordion-header" key={question.question_id}>
                  <Accordion.Button>
                    <i className="fas fa-align-justify fa-lg accordion-icon" />
                    <span className="text-wrap text-break"> {question.question_title}</span>
                  </Accordion.Button>
                </div>
                <Accordion.Body>
                  <div key={question.question_id}>
                    <span className="overflow-hidden text-truncate">{question.question_description || "Няма описание"}</span>
                  </div>
                  <div className="answers " key={idx}>
                    {
                      question.question_type !== 3 && question.answers?.length > 0 ? question.answers.map((answer, _idx) => (
                        <div className="answer" key={_idx}>
                          <span className="text-wrap text-break flex-grow-1">{answer.answer_text}</span>
                          {
                            answer.iscorrect ? (
                              <i className="fa fa-check flex-shrink-0 correct" />
                            ) : (
                              <i className="fa fa-times flex-shrink-0 incorrect" />
                            )
                          }
                        </div>
                      )) : <span>Помощни думи: {question.question_open_answer_help || question.hint}</span>
                    }
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            ))
          }
        </Accordion>
      </Form.Group>
    </Modal.Body>
  )
}

export default TestPreviwBody