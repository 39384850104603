import eu from "../../../../assets/images/eu-4.png";
import eu1 from "../../../../assets/images/eu1.png";
import "./MainFooter.scss"

const MainFooter = () => {
  return (
    <footer style={{ marginTop: 'auto' }}>
      <div className="main-footer footer clearfix mb-0 text-muted">
        <div className="float-start">
          <p style={{ fontSize: "12px" }}>Решения в областта на информационните и комуникационни технологии и киберсигурността в малките и средните предприятия</p>
        </div>
        <div className="float-end">
          <img src={eu} alt="EU" style={{ height: "4vh" }} />
          <img src={eu1} alt="EU" style={{ height: "4vh" }} />
        </div>
      </div>
    </footer>
  )
}


export default MainFooter;