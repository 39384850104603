import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';
import TestPreviwBody from '../../AddTestsBody/TestPreviewBody';
import { postData } from '../../../services/api';
import Swal from 'sweetalert2';
import { fetchData } from '../../../services/api';
import SelectTestModule from '../../ui/SelectTestModule';

function ImportTestModal({ fetchTests }) {
  const [show, setShow] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [isAddModule, setIsAddModule] = useState(false);
  const [err, setErr] = useState("");
  const [file, setFile] = useState({
    name: "",
    start_date: "",
    end_date: "",
    questions: [],
    module: ""
  });
  const [modulesList, setModulesList] = useState([]);
  async function getModulesList() {
    try {
      const response = await fetchData("/modules/list");
      if (response.status !== 201) throw new Error();
      let data = [];
      for (const module of response.data.data) {
        data.push({
          key: module.id,
          label: module.module_name || module.label,
          value: module.module_name || module.value,
        });
      }
      setModulesList(data);
    } catch (err) {
      console.log(err);
    }
  }

  const handleClose = () => {
    setIsPreview(false);
    setFile({
      name: "",
      start_date: "",
      end_date: "",
      questions: [],
      module: ""
    })
    setErr("");
    setIsAddModule(false)
    setShow(false);
  }

  const handleShow = () => setShow(true);

  const handleFileChange = (event) => {
    if (event.target.files.length === 0) {
      setIsPreview(false)
      setIsAddModule(false)
      return;
    }

    const file = event.target.files[0];
    const reader = new FileReader();

    if (file.type !== 'application/json') {
      isPreview && setIsPreview(false)
      setErr("Невалиден формат на файла! Моля изберете .json файл.")

      return;
    }

    reader.onload = function (event) {
      try {
        const jsonData = JSON.parse(event.target.result);
        setFile(file => ({
          ...file,
          name: jsonData.name,
          start_date: jsonData.start_date,
          end_date: jsonData.end_date,
          questions: jsonData.questions,
          module: {}
        }));
      } catch (error) {
        console.error('Invalid JSON format.', error);
      }
    };

    reader.onerror = function (event) {
      console.error("File could not be read! Code " + event.target.error.code);
    };

    reader.readAsText(file);
    getModulesList();
    setIsAddModule(true);
  };

  const handleSubmit = async (data) => {
    try {
      const response = await postData("/tests/add", file);
      if (response.status !== 200) throw new Error(response.data);
      handleClose();
      fetchTests()
      Swal.fire({
        title: " Успешно добавен тест!",
        icon: "success",
        confirmButtonText: "Към тестовете",
      });
    } catch (err) {
      console.log(err);
    }
  }

  const handlePreview = () => {
    setIsPreview(true);
    setIsAddModule(false);
  }

  const handleSelectModule = (e) => {
    setFile({ ...file, module: e });
  }

  const handleGoBack = () => {
    setIsPreview(false);
    setIsAddModule(true);
  }

  return (
    <>
      <Button variant="primary" onClick={handleShow} className="btn btn-primary mb-3 me-3 btn-success"
      >
        Прикачи тест
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{isPreview ? "Преглеждане на тест" : "Прикачване на тест"}</Modal.Title>

        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Control type="file" onChange={handleFileChange} disabled={isPreview}/>
          </Form.Group>
          {err && <p className="text-danger">{err}</p>}
          {isPreview && !isAddModule && <TestPreviwBody testData={file} />}
          {isAddModule && !isPreview && <SelectTestModule file={file} testModules={modulesList} onChange={handleSelectModule} />}
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-between '>
          <Button variant="danger" onClick={handleClose}>
            Затвори
          </Button>

          {(isPreview || isAddModule) && <div>
            
            {isAddModule && <Button variant="success" onClick={handlePreview}>
              Прегледай
            </Button>}

            {isPreview && <>
              <Button variant="warning mx-3" onClick={handleGoBack}>
                Назад
              </Button>
              <Button variant="success" onClick={() => handleSubmit(file)} >
                Запази
              </Button>
            </>}
          </div>}




        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ImportTestModal;