import { useRef, useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";

import "./CreateQuestionModal.scss";

const CreateQuestionModal = ({ onSaveQuestion }) => {
  const [show, setShow] = useState(false);
  const [questionType, setQuestionType] = useState(-1);
  const [newQuestion, setNewQuestion] = useState({
    question_id: Math.random(),
    question_title: "",
    question_description: "",
    question_open_answer_help: "",
    answers: [],
  });
  const [error, setError] = useState("");
  let valid = true;

  const answers_div = useRef(0);

  useEffect(() => { }, [newQuestion]);

  const validate = () => {
    const setErrorAndReturn = (errorMsg) => {
      valid = false;
      setError(errorMsg);
      return;
    };
  
    if (questionType === -1) {
      return setErrorAndReturn("Изберете тип въпрос");
    }
  
    if (!newQuestion.question_title) {
      return setErrorAndReturn("Въведете заглавие на въпроса");
    }
  
    if (!newQuestion.question_description) {
      return setErrorAndReturn("Въведете описание на въпроса");
    }
  
    switch (questionType) {
      case 1:
      case 2: {
        const minAnswers = questionType === 1 ? 2 : 3;
        if (newQuestion.answers.length < minAnswers) {
          return setErrorAndReturn(`Добавете поне ${minAnswers} отговора`);
        }
  
        let corrects = newQuestion.answers.reduce((count, answer) => {
          if (!answer.answer_text || answer.answer_text.trim() === "") {
            return setErrorAndReturn(`Добавете текст на отговора`);
          }
          return count + (answer.iscorrect === 1 ? 1 : 0);
        }, 0);
  
        if ((questionType === 1 && corrects === 0) || (questionType === 2 && corrects <= 1)) {
          return setErrorAndReturn(`Изберете поне ${questionType === 1 ? "един верен отговор" : "два верни отговора"}`);
        }
        break;
      }
      case 3: {
       


        break;
      }
      default:
        break;
    }
  
    return valid;
  };

  const handleSave = () => {
    if (!validate()) return;
    onSaveQuestion({
      ...newQuestion,
      question_type: questionType,
    });
    setShow(false);
    setError("")
    clearQuestion();
  };

  const clearQuestion = () => {
    setNewQuestion({
      question_id: Math.random(),
      question_title: "",
      question_description: "",
      answers: [],
    });
  };

  const typeChange = (e) => {
    if (!e.target.value) return;
    setQuestionType(Number(e.target.value));
    if (!answers_div.current) return;
    let answers_buff = newQuestion.answers;
    answers_buff.forEach((answer) => {
      answer.iscorrect = 0;
    });
    setNewQuestion({
      ...newQuestion,
      answers: answers_buff,
    });
    answers_div.current.childNodes.forEach((element) => {
      const check = element.querySelector(".form-check-input");
      check.checked = false;
    });
  };

  const answerTextChange = (e) => {
    const value = e.target.value;
    const id = e.target.parentElement.id;
    let answers_buff = newQuestion.answers;
    let idx = answers_buff.findIndex(
      (answer) => answer.answer_id === Number(id)
    );
    answers_buff[idx] = {
      ...answers_buff[idx],
      answer_text: value,
    };
    setNewQuestion({
      ...newQuestion,
      answers: answers_buff,
    });
  };

  const correctAnswerChange = (e) => {
    const id = e.target.parentElement.parentElement.id;
    const answers_div = e.target.parentElement.parentElement.parentElement;
    let answers_buff = newQuestion.answers;
    if (questionType === 1) {
      answers_div.childNodes.forEach((element) => {
        if (Number(element.id) !== Number(id)) {
          const check = element.querySelector(".form-check-input");
          check.checked = false;
        }
      });
      answers_buff.forEach((answer) => {
        answer.iscorrect = 0;
      });
    }
    let idx = answers_buff.findIndex(
      (answer) => answer.answer_id === Number(id)
    );
    answers_buff[idx].iscorrect = e.target.checked ? 1 : 0;
    setNewQuestion({
      ...newQuestion,
      answers: answers_buff,
    });
  };

  const hideModal = () => {
    setError("")
    setShow(false);
    setQuestionType(-1);
  };

  const openModal = () => {
    setQuestionType(-1);
    clearQuestion();
    setShow(true);
  };

  const addAnswer = () => {
    let answers_buff = newQuestion.answers;
    answers_buff.push({
      answer_id: Math.random(),
      answer_text: "",
      iscorrect: 0,
    });
    setNewQuestion({
      ...newQuestion,
      answers: answers_buff,
    });
  };

  const deleteAnswer = (e) => {
    const id = e.target.id;
    const answers_buff = newQuestion.answers.filter(
      (answer) => Number(answer.answer_id) !== Number(id)
    );
    setNewQuestion({
      ...newQuestion,
      answers: answers_buff,
    });
  };

  return (
    <>
      <div className="pb-4">
        <Button variant="add-item btn-primary" onClick={openModal}>
          Добави
        </Button>
      </div>
      <Modal show={show} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Добави въпрос</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Select onChange={typeChange}>
            <option hidden={true}>Избери тип въпрос</option>
            <option value="1">Един верен отговор</option>
            <option value="2">Много верни отговори</option>
            <option value="3">Отворен отговор</option>
          </Form.Select>
          {questionType !== -1 ? (
            <div className="add-question-modal-body">
              <Form.Label>Заглавие на въпроса</Form.Label>
              <Form.Control
                type="text"
                placeholder="Въведете заглавие"
                onChange={(e) =>
                  setNewQuestion({
                    ...newQuestion,
                    question_title: e.target.value,
                  })
                }
              />
              <Form.Label>Описание на въпроса</Form.Label>
              <Form.Control
                type="text"
                placeholder="Въведете описание"
                onChange={(e) =>
                  setNewQuestion({
                    ...newQuestion,
                    question_description: e.target.value,
                  })
                }
              />
              {questionType === 3 ? (
                <>
                  <Form.Label>Помощни думи</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Помощни думи"
                    defaultValue={newQuestion.hint}
                    onChange={(e) => {
                      setNewQuestion({
                        ...newQuestion,
                        question_open_answer_help: e.target.value,
                      });
                    }}
                  />
                </>
              ) : (
                ""
              )}
              {questionType === 1 || questionType === 2 ? (
                <div className="add-question-answers" ref={answers_div}>
                  {newQuestion.answers.map((answer) => (
                    <div
                      key={answer.answer_id}
                      className="add-question-answer"
                      id={answer.answer_id}
                    >
                      <Form.Check
                        type={
                          questionType === 1
                            ? "radio"
                            : questionType === 2
                              ? "checkbox"
                              : ""
                        }
                        onChange={correctAnswerChange}
                      />
                      <Form.Control
                        type="text"
                        placeholder="Въведете отговор"
                        onChange={answerTextChange}
                      />
                      <Button
                        variant="danger"
                        id={answer.answer_id}
                        onClick={deleteAnswer}
                      >
                        Изтрий
                      </Button>
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}

          {error ? <div className="alert alert-danger text-center font-weight-bold mt-3">{error}</div> : ""}
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <Button

            variant="success"
            onClick={addAnswer}
            disabled={!(questionType === 1 || questionType === 2)}
          >
            + Добави отговор
          </Button>
          <div className="d-flex gap-2">
            <Button variant="success" onClick={handleSave}>
              Добави
            </Button>
            <Button variant="danger" onClick={hideModal}>
              Затвори
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateQuestionModal;
