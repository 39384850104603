import { Button } from "react-bootstrap";
import FileSaver from "file-saver";
import { fetchData } from "../../../services/api";


const ExportTest = ({ id }) => {
  async function getTest(id) {
    try {
      const response = await fetchData(`/tests/by-id?test_id=${id}`);
      if (response.status !== 201) throw new Error();
      const data = response.data.data;
      const blob = new Blob([JSON.stringify(data)], { type: "application/json" });
      FileSaver.saveAs(blob, `${data.name}-${id}.json`);
    } catch (err) {
      console.log("error")

    }

  }

  return (
    <Button
      variant="success"
      size="sm"
      className="border-0"
      onClick={() => { getTest(id) }
      }
    >
      <i className="bi bi-download" /> Изтегли
    </Button>
  );
}

export default ExportTest;