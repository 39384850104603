import { Form } from "react-bootstrap";
import Select from 'react-select';


const SelectTestModule = ({ file, testModules, onChange }) => {
  return (
    <div style={{ marginTop: "4vh" }}>
      <Form.Group className="form-group">
        <Form.Label>Моля изберете модул за теста</Form.Label>
        <Select
          className="basic-single"
          classNamePrefix="select"
          isClearable={true}
          isSearchable={true}
          name="module"
          options={testModules}
          onChange={onChange}
          value={file.module}
        />
      </Form.Group>
    </div>
  )
}

export default SelectTestModule