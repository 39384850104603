import { Modal, Accordion, Card, Form, Button } from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css";
import TestPreviwBody from "../TestPreviewBody";

const TestPreview = ({ show, testData, hide, submit }) => {
    console.log(testData)
    return (
        <Modal show={show} onHide={hide} >
            <Modal.Header closeButton>
                <Modal.Title>Преглед на тест</Modal.Title>
            </Modal.Header>
            <TestPreviwBody testData={testData} />
            <Modal.Footer>
                <Button variant="success" onClick={submit}>
                    Продължи
                </Button>
                <Button variant="primary" onClick={hide}>
                    Затвори
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default TestPreview;