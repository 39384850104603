import { useState } from "react";
import { useNavigate } from 'react-router-dom'

import { postData } from '../../../services/api'

import TestInfo from '../../../components/AddTestsBody/TestInfo'
import CreateQuestions from '../../../components/AddTestsBody/CreateQuestions'
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import TestPreview from "../../../components/AddTestsBody/TestPreview";

const AddTest = () => {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();

  const getCurrentDate = () => {
    const now = new Date();
    const end = new Date(now);
    end.setHours(end.getHours() + 1);
    return {
      start: now,
      end: end
    }
  }

  const [testInfo, setTestInfo] = useState({
    name: "",
    start_date: getCurrentDate().start,
    end_date: getCurrentDate().end,
    module: {}
  });

  const [questions, setQuestions] = useState([]);

  const nextStep = () => {
    let currStep = step;
    currStep++;
    if (currStep <= 2) setStep(currStep);
  }

  const prevStep = () => {
    let currStep = step;
    currStep--;
    if (currStep >= 1) setStep(currStep);
    else {
      navigate("/admin/manage-tests")
    }
  }

  const save = async (data) => {
    try {
      const response = await postData("/tests/add", data);
      if (response.status !== 200) throw new Error(response.data);
      Swal.fire({
        title: " Успешно добавен тест!",
        icon: "success",
        confirmButtonText: "Към тестовете",
      }).then(function () {
        navigate("/admin/manage-tests")
      });
    } catch (err) {
      console.log(err);
    }
  }

  const updateTestInfo = (newTestData) => {
    setTestInfo(newTestData);
  }

  const updateQuestions = (newQuestionsData) => {
    setQuestions(newQuestionsData);
  }

  const hasMissingInfo = (data) => {
    if (
      data.questions.length !== 0 &&
      data.name
    ) return false;
    return true;
  }

  const submitAdd = (newQuestionsData) => {
    const data = {
      ...testInfo,
      questions: newQuestionsData
    };
    setTestInfo(data)
    if (hasMissingInfo(data)) {
      Swal.fire({
        title: "Неуспешно добавяне на тест!",
        icon: "error",
        text: "Моля добавете въпроси към теста!",
      });
      return;
    }
    showPreview(data);
  };

  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [previewData, setPreviewData] = useState({})

  const showPreview = (data) => {
    setPreviewData(data)
    setShowPreviewModal(true)
  }
  const submitPreview = async () => {
    setShowPreviewModal(false)
    save(previewData);
  }


  const methods = {
    onNextPage: nextStep,
    onPrevPage: prevStep,
    onUpdateTest: updateTestInfo,
    onUpdateQuestions: updateQuestions,
    onSubmit: submitAdd
  }

  return (
    <div className="row">
      <div className="create-module card">
        <div className="header">
          <h3>Създаване на тест</h3>
          <ul className="steps">
            <li className={step >= 1 ? `active` : ''}>Информация</li>
            <li className={step >= 2 ? `active` : ''}>Въпроси</li>
          </ul>
        </div>
        <div className="card-body">
          {step === 1 && (
            <TestInfo
              testData={testInfo}
              methods={methods}
            />
          )}
          {step === 2 && (
            <CreateQuestions
              questionsData={questions}
              methods={methods}
            />
          )}
          {
            showPreviewModal &&
            <TestPreview
              show={showPreviewModal}
              testData={previewData}
              hide={() => { setShowPreviewModal(false) }}
              submit={submitPreview}
            />
          }
        </div>
      </div>
    </div>
  )
}

export default AddTest;