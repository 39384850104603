import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css";
import { useEffect, useState } from "react";
import "./TestInfo.scss";
import { fetchData } from "../../../services/api";

const TestInfo = ({ testData, methods }) => {
  const [newTestData, setNewTestData] = useState(testData);
  const [nameValid, setNameValid] = useState(true);

  const [testModule, setTestModule] = useState({
    key: testData.module,
    label: "",
    value: "",
  });

  const areDatesValid = () => {
    const start_date = new Date(newTestData.start_date);
    const end_date = new Date(newTestData.end_date);

    return end_date > start_date ? true : false;
  };

  const handleSubmit = () => {
    if (!newTestData.name) {
      setNameValid(false);
      return;
    }
    if (!areDatesValid() || !nameValid) return;
    methods.onUpdateTest(newTestData);
    methods.onNextPage();
  };

  const [modulesList, setModulesList] = useState([]);

  useEffect(() => {
    async function getModulesList() {
      try {
        const response = await fetchData("/modules/list");
        if (response.status !== 201) throw new Error();
        let data = [];
        for (const module of response.data.data) {
          if (module.id === testData.module) {
            setNewTestData({
              ...newTestData,
              module: {
                key: module.id,
                label: module.module_name || module.label,
                value: module.module_name || module.value,
              },
            });
            setTestModule({
              key: module.id,
              label: module.module_name || module.label,
              value: module.module_name || module.value,
            });
          }
          data.push({
            key: module.id,
            label: module.module_name || module.label,
            value: module.module_name || module.value,
          });
        }
        setModulesList(data);
      } catch (err) {
        console.log(err);
      }
    }

    getModulesList();
  }, []);

  return (
    <>
      <div className="form-header d-flex justify-content-between ">
        <h4>Информация</h4>
      </div>
      <Form>
        <Form.Group className="module-name form-group">
          <Form.Label>Име на тест*</Form.Label>
          <Form.Control
            required
            type="text"
            className={!nameValid ? "invalid-date" : ""}
            placeholder="Име на тест"
            defaultValue={newTestData.name}
            onChange={(e) => {
              if (!nameValid && e.target.value) setNameValid(true);
              setNewTestData({ ...newTestData, name: e.target.value });
            }}
          />
          {!nameValid && (
            <span className="error-label">Добавете име на теста!</span>
          )}
        </Form.Group>
        <Form.Group className="form-group ">
          <Form.Label>Модул</Form.Label>
          <Select
            options={modulesList}
            placeholder={testData.module_name}
            name="job_titles"
            value={modulesList?.find(
              (option) => option.value === testData?.module?.value || option.value === testModule?.value
            )}

            onChange={(module) => {
              setNewTestData({
                ...newTestData,
                module,
              });
              setTestModule(module);
            }}
            isMulti={false}
          />
        </Form.Group>
        <Form.Group className="form-group ">
          <Form.Label>Начална дата на теста</Form.Label>
          <Flatpickr
            className="form-control"
            value={newTestData.start_date}
            onChange={(e) =>
              setNewTestData({ ...newTestData, start_date: e[0] })
            }
            options={{
              enableTime: true,
              dateFormat: "d/m/Y H:i",
              time_24hr: true,
            }}
          />
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label>Крайна дата на теста</Form.Label>
          <Flatpickr
            className={
              "form-control" + (!areDatesValid() ? " invalid-date" : "")
            }
            value={newTestData.end_date}
            name="end_date"
            onChange={(e) => setNewTestData({ ...newTestData, end_date: e[0] })}
            options={{
              enableTime: true,
              dateFormat: "d/m/Y H:i",
              time_24hr: true,
            }}
          />
          {!areDatesValid() ? (
            <span className="error-label">
              *Крайната дата не може да бъде по-малка или равна на началната
            </span>
          ) : (
            ""
          )}
        </Form.Group>
        <div className="form-footer">
          <Button className="form-buttons" onClick={methods.onPrevPage}>
            Назад
          </Button>
          <Button className="form-buttons" onClick={handleSubmit}>
            Напред
          </Button>
        </div>
      </Form>
    </>
  );
};

export default TestInfo;
