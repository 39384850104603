import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useRef } from "react";

import { logout } from "../../store/reducers/user";
import { hideBar } from "../../store/reducers/sidebar";
import SidebarItem from "./SidebarItem";
import Submenu from "./Submenu";
import SubmenuItem from "./Submenu/SubmenuItem/SubmenuItems";

import sideLogo from "../../assets/images/logo.webp";

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //sidebar toggle
  const sidebarRef = useRef(); // Step 2
  const specificElementRef = useRef(); // Specific element reference

  const { status } = useSelector((state) => state.sidebarReducer);

  //admin authorization
  const { user } = useSelector((state) => state.user);
  const userRole = user?.role_name?.toLowerCase();
  let showAdminPanel = false;
  let showRefrence = false;

  useEffect(() => {
    // Add 'no-scroll' class to body when sidebar is active
    if (status === "active" && window.innerWidth <= 1200) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [status]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        // Check if the clicked target is the specific element or a child of it
        if (
          specificElementRef.current &&
          specificElementRef.current.contains(event.target)
        ) {
          return; // If it is, simply return from the function
        }
        if (window.innerWidth <= 1200) {
          dispatch(hideBar()); // close the sidebar
        }
      }
    }

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);
    // Clean up event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch]);

  if (userRole === "admin" || userRole === "administrator") {
    showAdminPanel = true;
    showRefrence = true;
  } else if (userRole === "visitor") {
    showRefrence = true;
  }

  return (
    <div id="sidebar" className={`sidebar-desktop ${status}`} ref={sidebarRef}>
      <div
        className="sidebar-wrapper active ps ps--active-y"
        ref={specificElementRef}
      >
        <div className="sidebar-header position-relative">
          <Link to="/" href="index.html">
            <img src={sideLogo} alt="Logo" className="sidebar-logo"/>
          </Link>
        </div>
        <div className="sidebar-menu">
          <div className="menu">
            <div className="top-sidebar-menu">
              {showAdminPanel && (
                <div className="admin-menu active">
                  <Submenu
                    title="Администрация"
                    icon="person-workspace"
                    pathname="admin"
                  >
                    <SubmenuItem
                      text="Потребители"
                      icon="person-fill-add"
                      link="/admin/manage-users"
                    />
                    <SubmenuItem
                      text="Модули"
                      icon="folder-fill"
                      link="/admin/manage-modules"
                    />
                    <SubmenuItem
                      text="Тестове"
                      icon="clipboard-check-fill"
                      link="/admin/manage-tests"
                    />
                  </Submenu>
                </div>
              )}
              <div className="menu-dropdown">
                <div className="sidebar-title">Меню</div>
                <SidebarItem text="Начало" icon="house-door-fill" link="/" />
                <SidebarItem
                  text="Обучения"
                  icon="collection"
                  link="/my-courses"
                />
                <SidebarItem
                  text="Резултати"
                  icon="clipboard2-check-fill"
                  link="/results"
                />
                {showRefrence && (
                  <Submenu title="Справки" icon="search" pathname="references">
                    <SubmenuItem
                      text="Оценки"
                      icon="mortarboard-fill"
                      link="/references/grades"
                    />
                    <SubmenuItem
                      text="Служители"
                      icon="person-lines-fill"
                      link="/references/staff-members"
                    />
                    <SubmenuItem
                      text="Учебни материали"
                      icon="mortarboard-fill"
                      link="/references/materials"
                    />
                    <SubmenuItem
                      text="Тестове"
                      icon="clipboard-check-fill"
                      link="/references/tests"
                    />
                    <SubmenuItem
                      text="Прогрес на служителя"
                      icon="mortarboard-fill"
                      link="/references/study-progress"
                    />
                  </Submenu>
                )}
                {userRole !== 'visitor' ?
                  (
                    <SidebarItem
                      text="Комуникация"
                      icon="chat-left-text"
                      link="/chat"
                    />
                  ) : ("")
                }
              </div>
            </div>
            <div className="bottom mt-10">
              <SidebarItem
                text="Профил"
                icon="person-badge-fill"
                link="/profile"
              />
              <div
                className="sidebar-item"
                onClick={() => {
                  dispatch(logout());
                  navigate("/login");
                }}
              >
                <span className="sidebar-link">
                  <i className={`bi bi-door-open-fill`}></i>
                  <span>Изход</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
